import { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';

import { userContext } from '../context/userContext';
import { getUser } from '../util/functions';
import { DESIGNER, DEVELOPER } from '../util/constants';

export function useGetData() {
	const [state, setState] = useState({
		fetching: true,
		tungaUser: null,
	});
	const { user } = useContext(userContext);
	const storage = firebase.storage().ref();

	// Fetch user data
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userData = await getUser({ userTungaID: user.tunga_id });
				const isAdminUser = ![DEVELOPER, DESIGNER].includes(userData?.role);

				// check if id_status exists
				const idStatus = userData.id_status || {
					status: 'unknown',
					expiry_date: null,
				};

				setState((prev) => ({
					...prev,
					tungaUser: { ...userData, id_status: idStatus, isAdminUser },
				}));
			} catch (error) {}
		};

		fetchUserData();
	}, [user]);

	// Updated image fetching logic
	useEffect(() => {
		const { tungaUser } = state;
		if (!tungaUser?.fs_image_destination) return;

		let isMounted = true;

		const fetchImage = async (path) => {
			if (!path) return null;
			try {
				const url = await storage.child(path).getDownloadURL();
				return url;
			} catch (error) {
				return null;
			}
		};

		const first_name = tungaUser.first_name
			? tungaUser.first_name
			: tungaUser.firstname || '';
		const last_name = tungaUser.last_name
			? tungaUser.last_name
			: tungaUser.lastname || '';

		const loadImages = async () => {
			const [profileImage, barcodeImage] = await Promise.all([
				fetchImage(tungaUser.fs_image_destination),
				fetchImage(tungaUser.fs_qr_destination),
			]);

			if (isMounted) {
				setState((prev) => ({
					fetching: false,
					tungaUser: {
						...prev.tungaUser,
						profile_image: profileImage,
						barcode_image: barcodeImage,
						first_name,
						last_name,
					},
				}));
			}
		};

		loadImages();

		return () => {
			isMounted = false;
		};
	}, [state.tungaUser?.fs_image_destination]);

	return {
		fetching: state.fetching,
		tungaUser: state.tungaUser,
		setTungaUser: (tungaUser) => setState((prev) => ({ ...prev, tungaUser })),
		setFetching: (fetching) => setState((prev) => ({ ...prev, fetching })),
	};
}
